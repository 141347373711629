import React from "react"
import { Link, graphql } from "gatsby"
import { motion } from "framer-motion"
import Layout from "../components/layout"
import SEO from "../components/shared/seo"
import RatingComponent from "../components/shared/ratings"
import Img from "gatsby-image"
import {
  TwitterShareButton,
  EmailShareButton,
  TwitterIcon,
  EmailIcon,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share"

const BlogPostTemplate = ({ data, pageContext, location }) => {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata.title
  const { previous, next } = pageContext
  const url = location.href ? location.href : ""
  console.log(post.frontmatter.tags)
  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <article className="w-full  tracking normal self-center font-body my-4 md:mt-24 md:mb-16">
        <header className="flex items-start font-header leading-none ">
          <div className="md:w-2/3 w-full md:mr-24  content-end">
            <h1 className="md:text-6xl text-3xl  ">{post.frontmatter.title}</h1>
            <p className="md:text-3xl text-xl font-body">
              {post.frontmatter.author}
            </p>
            <div className="my-8">
              <RatingComponent
                size="20px"
                rating={post.frontmatter.rating}
              ></RatingComponent>
            </div>
            <div className="text-xl font-body font-light tracking-widest my-4">
              At a Glance
            </div>
            <div className="italic font-body text-lg  text-justify ">
              {post.frontmatter.abstract}
            </div>
            <div className="mt-8">
              <a
                href={post.frontmatter.website}
                className="text-xl hover:text-blue font-body"
              >
                {post.frontmatter.website}
              </a>
            </div>
            <p className="my-8">{post.frontmatter.date}</p>
            <TwitterShareButton className="" url={url}>
              <TwitterIcon size={32} round />
            </TwitterShareButton>
            <WhatsappShareButton className="mx-2" url={url}>
              <WhatsappIcon size={32} round />
            </WhatsappShareButton>
            <EmailShareButton url={url}>
              <EmailIcon size={32} round />
            </EmailShareButton>
          </div>

          <Img
            className="w-1/3 mb-8 rounded"
            fluid={post.frontmatter.thumbnail.childImageSharp.fluid}
          ></Img>
        </header>
        {/* <div className="w-full border-b border-black my-8"></div> */}
        {/* <div className="text-2xl my-4">At a Glance</div>
        <div className="italic">{post.frontmatter.abstract}</div> */}
        <div className="mx-8 justify-center border-b border-black my-16"></div>
        <section
          className="tracking-normal text-justify my-16  text-lg"
          dangerouslySetInnerHTML={{ __html: post.html }}
        />
      </article>

      <nav>
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: `2rem`,
          }}
        >
          <li>
            {previous && (
              <Link to={previous.fields.slug} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={next.fields.slug} rel="next">
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul>
      </nav>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        author
        title
        website
        abstract
        rating
        tags
        date(formatString: "MMMM DD, YYYY")
        thumbnail {
          childImageSharp {
            fluid(maxWidth: 500, quality: 50) {
              ...GatsbyImageSharpFluid
            }
          }
          publicURL
        }
      }
    }
  }
`
